//跨域代理前缀
const API_PROXY_PREFIX='/api'
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX
const VERSION = process.env.VUE_APP_API_VERSION
module.exports = {

  trial_apply: `${BASE_URL}/web/${VERSION}/trial_apply/add`,
  article_list: `${BASE_URL}/web/${VERSION}/article/getArticlePage`,
  article_detail: `${BASE_URL}/web/${VERSION}/article/detail`,

}