import {trial_apply, article_list, article_detail} from './api'
import {request, METHOD} from './request'

/**
 * 提交试用
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function trialApply(data = {}) {
  return request(trial_apply, METHOD.POST, data )
}


/**
 * 文章列表
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function articleList(params = {}) {
  return request(article_list, METHOD.GET, params )
}

/**
 * 文章详情
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
 export async function articleDetail(params = {}) {
  return request(article_detail, METHOD.GET, params )
}






export default {
  trialApply,
  articleList,
  articleDetail
}
