<template>
  <div class="container">
    <h2 class="news-page-title">新闻资讯</h2>
    <div v-for="(item,i) in dataSource" :key="i" class="row news-page-list border-bottom d-flex" :class="{' border-top':i === 1}">
      <div class="col-xl-4 col-sm-12">
         <img :src="item.cover" alt="" />
      </div>
      <div class="col-xl-8 col-sm-12">
        <div class="news-item-detail">
          <p class="time">{{item.create_time}}</p>
          <p class="title">{{item.title}}</p>
          <p class="desc">{{item.short_desc}}</p>
        </div>
      </div>
    </div>
    <div class="news-page-page text-center">
      <a-pagination v-model="pageNo" :pageSize="pageSize" :total="total" @change="onChange" />
    </div>
  </div>
</template>

<script>
import { articleList } from "@/services/news";
export default {
  data() {
    return {
      pageNo:1,
      pageSize:10,
      total:0,
      dataSource: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList() {
      const { pageNo, pageSize } = this;
      articleList({
        pageNo,
        pageSize,
      }).then(res =>{
        const { code, msg } = res.data;
        if (code === 200) {
          const { data, pageNo, pageSize, totalCount } = res.data.data;
          this.dataSource = data;
          this.pageNo = pageNo;
          this.total = totalCount;
          this.pageSize = pageSize;
        }else{
          this.$message.error(msg);
        }

      })
    },
    onChange(page, pageSize){
      this.pageNo = page;
      this.pageSize = pageSize;
      this.getList()
    }
  },
}
</script>

<style lang="less" scoped>
  .news-page-title{
    text-align: center;
    color: #222222;
    font-size:45px;
    padding:60px 0 40px 0;
  }
  .news-page-list{
    padding:30px 0;
    img{
      width:100%;
    }
  }
  .news-page-page{
    margin:40px 0 70px 0;
  }
</style>
